import React, { useState } from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import { Link } from 'gatsby';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Section from '../components/Section/Section';
import Headline from '../components/Headline/Headline';

const LeistungenPage = () => (
  <Layout headerWhite>
    <SEO
      title="Impressum | Dr. Kostka - integrative Medizin und Ästhetik"
      description="Dr. K. Kostka bietet integrative Medizin und Ästhetik mitten in Bochum. Moderne Praxis ✓ Top Ausstattung ✓ Vereinbaren Sie jetzt einen Termin ✓"
    />
    <Section>
      <Container>
        <Headline subtitle="Praxis für integrative Medizin und Ästhetik">Impressum</Headline>
        <h2>Angaben gemäß § 5 TMG:</h2>
<p>Praxis für integrative Medizin und Ästhetik</p>

<h3>Gesetzliche Berufsbezeichnung:</h3>
<p>Fachärztin für Anästhesie</p>

<h3>Postanschrift:</h3>
<p>Dr.-Ruer-Platz 1
<br/>44787 Bochum
<br/></p>

<h3>Kontakt:</h3>
<p>Telefon: 0234/978 428 10
<br/>Telefax: 0234/978 428 29
<br/>E-Mail: praxis@kostka-bochum.de</p>

<h3>Vertreten durch:</h3>
<p>Dr. Kathrin Kostka<br/></p>

<h3>Aufsichtsbehörde</h3>
<p>Ärztekammer Westfalen-Lippe</p>

<h3>Zuständige Kammer:</h3>
<p>Ärztekammer Westfalen-Lippe
<br/>Körperschaft des öffentlichen Rechts
<br/>Gartenstraße 210-214
<br/>48147 Münster
<br/>Telefon: 0251/9290
<br/>Fax: 0251/9292999 
<br/>www.aekwl.de
<br/></p>

<h3>Berufsrechtliche Regelungen</h3>
<p><a href="https://www.aekwl.de/fileadmin/rechtsabteilung/doc/Berufsordnung.pdf">Berufsordnung der Ärztekammer Westfalen-Lippe</a></p>
<p><a href="https://www.aekno.de/aerzte/gesetze-verordnungen/heilberufsgesetz-nrw">Heilberufsgesetz des Landes NRW</a></p>


<p></p><h2>Hinweise zur Website</h2>
<p></p><p><em>Das Impressum wurde mit dem <a href="https://www.activemind.de/datenschutz/impressums-generator/">Impressum-Generator der activeMind AG</a> erstellt.</em></p>

      </Container>
    </Section>
  </Layout>
);

export default LeistungenPage;
